import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { IoArrowBackCircle } from "react-icons/io5";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AppDetails from "../Appointment/AppDetail";
import axios from "axios";

function Calender1() {
  const [value, onChange] = useState(new Date());
  const [isDisplay, setIsDisplay] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState("");
  const { refreshTable, currentUser } = useSelector((state) => state.user);
  const branch = currentUser?.branch_name;
  const token = currentUser?.token;
  const user = currentUser;
  const [branchDetail, setBranchDetail] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [appointmentsData, setAppointmentsData] = useState([]);

  const StyledCalendar = styled(Calendar)`
    .react-calendar__month-view__days__day--weekend {
      color: black;
    }
  `;

  const filteredAppointmentData = appointmentsData?.filter(
    (appointment) => appointment.appointment_status !== "Cancel"
  );
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);

  const getBranchDetail = async () => {
    try {
      const { data } = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/v1/receptionist/get-branch-detail/${branch}/${user?.hosId}`
      );
      console.log(data);
      setBranchDetail(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDoctors = async () => {
    try {
      const response = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/v1/receptionist/get-doctors/${branch}/${user?.hosId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("all doctors and not used  - ", response?.data?.data);
      setDoctors([{ ...currentUser }]);
    } catch (error) {
      console.log(error);
    }
  };
  const getAppointments = async () => {
    try {
      const response = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/v1/receptionist/get-appointments/${user?.hosId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAppointmentsData(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBranchDetail();
    getAppointments();
    getDoctors();
  }, []);
  useEffect(() => {
    getAppointments();
  }, [refreshTable]);

  useEffect(() => {
    if (doctors.length > 0) {
      setSelectedDoctor(doctors[0].employee_ID);
    }
  }, [doctors]);

  const handleDayClick = (selectedDate) => {
    setIsDisplay(true);
    setTimeSlots([]);

    const startTime = new Date(selectedDate);

    startTime?.setHours(
      parseInt(branchDetail[0]?.open_time?.split(":")[0]),
      0,
      0
    );
    const endTime = new Date(selectedDate);
    endTime.setHours(
      parseInt(branchDetail[0]?.close_time?.split(":")[0]),
      0,
      0
    );

    const slots = [];
    let currentTime = new Date(startTime);

    while (currentTime < endTime) {
      const timeSlot = currentTime.toISOString();
      slots.push(timeSlot);
      currentTime.setMinutes(
        currentTime.getMinutes() +
          parseInt(branchDetail[0]?.appoint_slot_duration?.split(" "))
      );
    }

    setTimeSlots(slots);
  };

  const handleTimeSlotClick = (timeSlot) => {
    const slotTime = new Date(timeSlot);
    const clickedAppointment = filteredAppointmentData.find((appointment) => {
      const appointmentTime = new Date(appointment.appointment_dateTime);

      return (
        appointmentTime.getFullYear() === slotTime.getFullYear() &&
        appointmentTime.getMonth() === slotTime.getMonth() &&
        appointmentTime.getDate() === slotTime.getDate() &&
        appointmentTime.getHours() === slotTime.getHours() &&
        appointmentTime.getMinutes() === slotTime.getMinutes() &&
        appointment.assigned_doctor_id === selectedDoctor
      );
    });

    if (clickedAppointment) {
      setSelectedAppointment(clickedAppointment);
      setShowPopup(true);
    }
  };

  const getCellStyle = (time) => {
    const slotTime = new Date(time);

    const appointment = filteredAppointmentData.find((appointment) => {
      const appointmentTime = new Date(appointment?.appointment_dateTime);

      return (
        appointmentTime.getFullYear() === slotTime.getFullYear() &&
        appointmentTime.getMonth() === slotTime.getMonth() &&
        appointmentTime.getDate() === slotTime.getDate() &&
        appointmentTime.getHours() === slotTime.getHours() &&
        appointmentTime.getMinutes() === slotTime.getMinutes() &&
        appointment.assigned_doctor_id === selectedDoctor
      );
    });
    if (!appointment) {
      return "bg-warning";
    }

    if (appointment) {
      return "bg-success";
    }
  };

  const divideIntoColumns = (timeSlots, columns) => {
    const dividedTimeSlots = [];
    const slotsPerColumn = Math.ceil(timeSlots.length / columns);

    for (let i = 0; i < columns; i++) {
      dividedTimeSlots.push(
        timeSlots.slice(i * slotsPerColumn, (i + 1) * slotsPerColumn)
      );
    }

    return dividedTimeSlots;
  };

  const columns = 10;
  const timeSlotsColumns = divideIntoColumns(timeSlots, columns);
  console.log(timeSlotsColumns);

  const formatDate = (date) => {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString()?.split("T")[0];
  };

  return (
    <Wrapper>
      <div>
        <div>
          <h6 className="text-center fw-bold">View summary by date</h6>
        </div>
        <div className={isDisplay ? "d-none" : "d-block"}>
          <div>
            <StyledCalendar
              onChange={onChange}
              onClickDay={handleDayClick}
              value={value}
            />
          </div>
        </div>

        <div className={isDisplay ? "d-block time-slots" : "d-none"}>
          <div
            className=" mx-auto
     mt-1 mb-1  d-flex justify-content-around"
          >
            <div className="w-50">
              <span
                className="backIcon"
                onClick={() => {
                  setIsDisplay(false);
                }}
              >
                <IoArrowBackCircle />
              </span>
            </div>
            <div className="w-50">
              {" "}
              <select
                className="form-select text-capitalize"
                onChange={(e) => setSelectedDoctor(e.target.value)}
                style={{ cursor: "pointer" }}
              >
                {doctors?.map((doctor) => (
                  <option
                    value={doctor.employee_ID}
                    className="text-capitalize"
                  >
                    {"Dr. "}
                    {doctor.employee_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="text-end my-1">
            <p className="">
              {value.toLocaleDateString("en-US", {
                weekday: "short",
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}
            </p>
          </div>
          <div className="d-flex justify-content-around align-items-center">
            <div className="bg-success mb-2 rounded-2">
              <p className="p-1 my-auto">Scheduled</p>
            </div>
            <div className="bg-warning mb-2 rounded-2">
              <p className="p-1 my-auto">Unscheduled</p>
            </div>
          </div>

          <div className="table-responsive" id="tab">
            <table className="table ">
              <tbody>
                {timeSlotsColumns?.map((column, columnIndex) => (
                  <tr key={columnIndex}>
                    {column?.map((timeSlot, index) => (
                      <td
                        key={index}
                        className={getCellStyle(timeSlot)}
                        onClick={() => handleTimeSlotClick(timeSlot)}
                        style={
                          getCellStyle(timeSlot) === "bg-success"
                            ? { cursor: "pointer" }
                            : {}
                        }
                      >
                        {new Date(timeSlot).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {showPopup && (
          <AppDetails
            onClose={() => setShowPopup(false)}
            slotInfo={selectedAppointment}
          />
        )}
      </div>
    </Wrapper>
  );
}

export default Calender1;
const Wrapper = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  margin-left: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 768px) {
    margin: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 1020px) {
    margin: auto;
  }
  @media screen and (min-width: 2000px) and (max-width: 2500px) {
    margin-left: 20px;
    width: 90%;
  }

  .cal {
    @media screen and (max-width: 768px) {
      width: 80%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 80%;
    }
  }
  .react-calendar {
    height: 28.8rem;
    width: 415px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 2.4rem;
    @media screen and (max-width: 768px) {
      width: 90%;
      margin: auto;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 90%;
      margin: auto;
    }
  }
  .time-slots {
    height: 28.8rem;
  }
  .backIcon {
    font-size: 30px;
    cursor: pointer;
  }
  td {
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 7px;
    white-space: nowrap;
  }
`;
