import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toggleTableRefresh } from "../../../../redux/user/userSlice";
import { useNavigate } from "react-router-dom";

const SaveData = ({ id, tpid }) => {
  const navigate = useNavigate();
  console.log(id);
  const [data, setData] = useState([]);
  const { refreshTable, currentUser } = useSelector((state) => state.user);
  const user = useSelector((state) => state.user.currentUser);
  const token = user.token;

  const getData = async () => {
    try {
      const { data } = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/doctor/getDentalDataByID/${id}/${tpid}/${user?.hosId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, [id, refreshTable]);

  console.log(data);

  const diseases = new Set(
    data.flatMap((entry) =>
      entry.disease.split(", ").map((disease) => disease.trim())
    )
  );

  console.log([...diseases]);

  const timelineForDelete = async () => {
    try {
      const response = await axios.post(
        "https://dentalguru-lite.dentalguru.software/api/doctor/insertTimelineEvent",
        {
          type: "Examination",
          description: "Add Teeth Pediatric DentalX",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const confirmed = window.confirm("Are you sure you want to delete?");

      if (confirmed) {
        const res = await axios.delete(
          `https://dentalguru-lite.dentalguru.software/api/doctor/deleteDentalPediatric/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(res.data);
        setData(data.filter((item) => item.exm_id !== id));
      }
    } catch (error) {
      console.log(error);
      window.alert("An error occurred while deleting the item.");
    }
  };

  const handleSaveContinue = () => {
    navigate(`/treatmentSuggestion/${id}/${tpid}`);
  };

  return (
    <Wrapper>
      <div className="container-fluid">
        <div className="row">
          <h2>Saved Data</h2>
          {data && data?.length > 0 ? (
            <div className="table-responsive ">
              <table className="table table-bordered table-striped table-secondary border border-secondary">
                <thead>
                  <tr>
                    <th>SELECTED TEETH</th>
                    <th>DISEASE</th>
                    <th>CHIEF COMPLAIN</th>
                    <th>ADVICE</th>
                    <th>ON EXAMINATION</th>
                    <th>Diagnose Category</th>

                    <th>DELETE ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.selected_teeth}</td>
                      <td>{item.disease}</td>
                      <td>{item.chief_complain}</td>
                      <td>{item.advice}</td>
                      <td>{item.on_examination}</td>
                      <td>{item.diagnosis_category}</td>
                      <td>
                        <button
                          className="btn btn-danger mx-1 justify-content-end shadow"
                          style={{
                            backgroundColor: "#0dcaf0",
                            border: "#0dcaf0",
                          }}
                          onClick={() => handleDelete(item.exm_id)}
                        >
                          <MdDelete size={20} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-color text-light mx-3"
                  onClick={handleSaveContinue}
                >
                  Next
                </button>
              </div>
            </div>
          ) : (
            <p>There is no saved data.</p>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default SaveData;
const Wrapper = styled.div`
  .updatelable {
    font-size: 1rem;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    padding-left: 0.9rem;
  }
  .btn-color {
    background-color: rgb(8 145 178);
  }
`;
