import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import cogoToast from "cogo-toast";
import { useSelector } from "react-redux";
import moment from "moment";
import { FaPrint } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { SiGmail } from "react-icons/si";

const PrintOpdBill = () => {
  const { appointmentId } = useParams();
  const contentRef = useRef();
  const user = useSelector((state) => state.user?.currentUser);
  const { currentBranch } = useSelector((state) => state.branch);
  const branch = user?.branch_name;
  const token = user?.token;
  const [data, setData] = useState("");
  const [getBranch, setGetBranch] = useState([]);
  const navigate = useNavigate();

  const getBranchDetails = async () => {
    try {
      const { data } = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/v1/receptionist/getBranchDetailsByBranch/${branch}/${user?.hosId}`
      );
      console.log(data);
      setGetBranch(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getBill = async () => {
    try {
      const response = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/v1/receptionist/get-appointment-by-id/${branch}/${appointmentId}/${user?.hosId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response?.data?.data);
      setData(response?.data?.data);
    } catch (error) {
      console.log(error);
      cogoToast.error("error in getting appointment");
    }
  };

  useEffect(() => {
    getBill();
    getBranchDetails();
  }, []);

  const handlePrint = () => {
    window.print();
  };

  const handleDownloadPdf = async () => {
    const element = contentRef.current;
    const canvas = await html2canvas(element, { scale: 2 });
    const imgData = canvas.toDataURL("image/jpeg", 0.75);
    const pdf = new jsPDF();
    const imgWidth = 210;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, undefined, "FAST");
    pdf.save("sitting bill.pdf");
  };

  const sendPrescriptionMail = async () => {
    if (!data?.emailid) {
      alert("Email id not available");
      return;
    }

    if (!contentRef.current || !data) {
      console.error("Content reference or patient data is missing.");
      cogoToast.error("Content or patient data not found.");
      return;
    }

    try {
      const element = contentRef.current;
      const canvas = await html2canvas(element, { scale: 2 });
      const imgData = canvas.toDataURL("image/jpeg", 0.75);
      const pdf = new jsPDF();
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        imgWidth,
        imgHeight,
        undefined,
        "FAST"
      );
      const pdfData = pdf.output("blob");
      const formData = new FormData();
      formData.append("email", data.emailid);
      formData.append("patient_name", data.patient_name);
      formData.append("subject", `${data.patient_name}, your OPD bill file`);

      const clinicDetails = currentBranch[0] || {};
      const textMatter =
        `Dear ${data.patient_name},\n\nPlease find the attached OPD bill file.\n\n` +
        `Clinic Details:\n` +
        `Name: ${clinicDetails.hospital_name || "N/A"}\n` +
        `Contact: ${clinicDetails.branch_contact || "N/A"}\n` +
        `Address: ${clinicDetails.branch_address || "N/A"}\n` +
        `Email: ${clinicDetails.branch_email || "N/A"}\n\n` +
        `Thank you for choosing ${
          clinicDetails.hospital_name || "our clinic"
        }.\n\n` +
        `Best regards,\n` +
        `${clinicDetails.hospital_name || "Clinic"} Team`;

      formData.append("textMatter", textMatter);
      formData.append("file", pdfData, "OPD_bill.pdf");

      console.log("Form data prepared:");
      Array.from(formData.entries()).forEach(([key, value]) => {
        console.log(`${key}: ${value}`);
      });

      cogoToast.success("Sending OPD bill to email...");

      const response = await axios.post(
        "https://dentalguru-lite.dentalguru.software/api/doctor/prescriptionOnMail",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          timeout: 15000,
        }
      );

      if (response.status === 200) {
        cogoToast.success("OPD bill sent successfully!");
        console.log("PDF sent successfully:", response.data);
      } else {
        cogoToast.error("Failed to send bill, server error.");
        console.error("Failed to send PDF, server error:", response);
      }
    } catch (error) {
      console.error("Error sending PDF:", error);
      cogoToast.error("Error sending bill.");
    }
  };

  const sendPrescriptionWhatsapp = async () => {
    try {
      const element = contentRef.current;
      const canvas = await html2canvas(element, { scale: 2 });
      const imgData = canvas.toDataURL("image/jpeg", 0.75);
      const pdf = new jsPDF();
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        imgWidth,
        imgHeight,
        undefined,
        "FAST"
      );
      const pdfData = pdf.output("blob");
      console.log(pdfData);

      const formData = new FormData();
      formData.append("phoneNumber", data?.mobileno);
      formData.append("message", "test message");

      // formData.append("message", `Dear ${data?.patient_name}, Please find the attached OPD bill file.\n` +
      //   `Clinic Details:\n` +
      //   `Name: ${currentBranch[0]?.hospital_name}\n` +
      //   `Contact: ${currentBranch[0]?.branch_contact}\n` +
      //   `Address: ${currentBranch[0]?.branch_address}\n` +
      //   `Email: ${currentBranch[0]?.branch_email}\n\n` +
      //   `Thank you for choosing ${currentBranch[0]?.hospital_name}.\n\n` +
      //   `Best regards,\n` +
      //   `${currentBranch[0]?.hospital_name} Team`
      // );

      // Convert Blob to a File
      const file = new File([pdfData], "OPD bill.pdf", {
        type: "application/pdf",
      });

      formData.append("media_url", file);
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }

      const res = await axios.post(
        "https://dentalguru-lite.dentalguru.software/api/v1/receptionist/sendWhatsapp",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      cogoToast.success("OPD bill sent successfully");
      console.log("PDF sent successfully");
    } catch (error) {
      console.error("Error sending PDF:", error);
      cogoToast.error("Error to send OPD bill");
    }
  };

  console.log(getBranch);

  return (
    <Container>
      <div ref={contentRef}>
        <div className="headimage">
          <img src={getBranch[0]?.head_img} alt="header" srcset="" />
        </div>
        <div className="container-fluid mt-2 p-0">
          <div className="row">
            <div className="col-12 ">
              <h4 className="text-center heading"> OPD RECIEPT</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th className="text-start">Appointment Id</th>
                    <td className="text-capitalize">
                      {": "}
                      {data?.appoint_id}
                    </td>

                    <th scope="col" className="text-start">
                      Patient id
                    </th>
                    <td className="text-capitalize">
                      {": "}
                      {data?.uhid}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col" className="text-start">
                      Branch Name
                    </th>
                    <td className="text-capitalize">
                      {": "}
                      {data?.branch_name}
                    </td>
                    <th scope="col" className="text-start">
                      Patient Type
                    </th>
                    <td className="text-capitalize">
                      {": "}
                      {data?.patient_type}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col" className="text-start">
                      Name
                    </th>
                    <td className="text-capitalize">
                      {": "}
                      {data?.patient_name}
                    </td>
                    <th scope="col" className="text-start pe-5">
                      Mobile No
                    </th>
                    <td className="text-capitalize">
                      {": "}
                      {data?.mobileno}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col" className="text-start">
                      Gender
                    </th>
                    <td className="text-capitalize">
                      {": "}
                      {data?.gender}
                    </td>

                    <th scope="col" className="text-start">
                      Appointment
                    </th>
                    <td className="text-capitalize">
                      {": "}
                      {data?.appointment_dateTime
                        ? moment(
                            data?.appointment_dateTime,
                            "YYYY-MM-DDTHH:mm"
                          ).format("DD/MM/YYYY hh:mm A")
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col" className="text-start">
                      Age
                    </th>
                    <td className="text-capitalize">
                      {": "}
                      {data?.age}
                    </td>
                    <th scope="col" className="text-start">
                      Doctor Name
                    </th>
                    <td className="text-capitalize">
                      {": "}
                      {"Dr. "}
                      {data?.assigned_doctor_name}
                    </td>
                  </tr>

                  <tr>
                    <th scope="col" className="text-start">
                      {" "}
                      Date
                    </th>
                    <td className="text-capitalize">
                      {": "}
                      {data?.created_at
                        ? moment(data?.created_at).format("DD/MM/YYYY")
                        : ""}
                    </td>
                    <th scope="col" className="text-start">
                      Payment Mode
                    </th>
                    <td className="text-capitalize">
                      {": "}
                      {data.payment_Mode}
                    </td>
                  </tr>

                  <tr>
                    {data.payment_Mode == "online" && (
                      <>
                        <th scope="col" className="text-start">
                          Transaction Id
                        </th>
                        <td className="text-capitalize">
                          {": "}
                          {data?.transaction_Id}
                        </td>{" "}
                      </>
                    )}
                    <th scope="col" className="text-start">
                      Payment Status
                    </th>
                    <td className="text-capitalize">
                      {": "}
                      {data.payment_Status}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col" className="text-start">
                      Address
                    </th>
                    <td
                      className="text-capitalize"
                      style={{ whiteSpace: "normal" }}
                    >
                      {": "}
                      {data?.address}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row proc-detail">
            <div className="col-12">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th className="text-start ps-4 pb-2 pt-1 ">S.No.</th>
                    <th
                      scope="col"
                      colSpan="1"
                      className="text-start pb-2 pt-1 code-column"
                    >
                      Treatment Name
                    </th>
                    <th scope="col" className="text-end pe-4 pb-2 pt-1">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-start ps-4 ">{"1"}</td>
                    <td
                      colSpan="1"
                      style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                      className="text-start code-column"
                    >
                      OPD
                    </td>
                    <td className="text-end pe-4">
                      {data.opd_amount}
                      {".00"}
                    </td>
                  </tr>

                  <tr>
                    <th
                      scope="col"
                      className=""
                      style={{ width: "30% !important" }}
                    ></th>

                    <th scope="col" className="text-start pt-4 second-th">
                      Payable Amount INR
                    </th>

                    <th scope="col" className="text-end pe-4 pt-4">
                      {" "}
                      {data.opd_amount}
                      {".00"}
                    </th>
                  </tr>
                  <tr>
                    <th scope="col" className="" style={{ width: "30%" }}></th>
                    <th scope="col" className="text-start second-th">
                      {" "}
                      Amount Paid INR
                    </th>

                    <th scope="col" className="text-end pe-4">
                      {data.opd_amount}
                      {".00"}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 d-flex align-items-end ">
            <div>
              <h6 className="ms-5 preparedBy">
                Prepared by{" "}
                <span className="text-uppercase">
                  {data?.appointment_created_by}
                </span>
              </h6>
            </div>
          </div>
        </div>

        <div className="footimage d-none d-print-block">
          <img src={getBranch[0]?.foot_img} alt="footer" srcset="" />
        </div>
      </div>
      <div className="d-flex justify-content-center my-3 gap-2">
        <button
          type="button"
          className="btn btn-info btn-lg shadow"
          onClick={() => window.history.go(-1)}
        >
          <IoMdArrowRoundBack /> Back
        </button>
        <button
          type="button"
          className="btn btn-info btn-lg shadow"
          onClick={handlePrint}
        >
          <FaPrint /> Print
        </button>

        <button
          type="button"
          className="btn btn-info btn-lg shadow"
          onClick={() => navigate("/receptionist-dashboard")}
        >
          <FaHome /> Go to Dashboard
        </button>
      </div>
      <div className="container-fluid">
        <div className="text-center">
          <br />
          <span className="fs-5 fw-bold no-print"> Share on : </span>
          {currentBranch[0]?.sharemail === "Yes" && (
            <button
              className="btn btn-info no-print mx-3 mb-3 mt-2 text-white shadow"
              style={{
                backgroundColor: "#0dcaf0",
                border: "#0dcaf0",
              }}
              onClick={sendPrescriptionMail}
            >
              <SiGmail />
            </button>
          )}
        </div>
      </div>
    </Container>
  );
};

export default PrintOpdBill;

const Container = styled.div`
  font-family: "Times New Roman", Times, serif;
  overflow-x: hidden;
  background-color: white;

  .code-column {
  }

  .headimage {
    height: 18rem;
    width: auto;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .footimage {
    @media print {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }

    width: auto;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .heading {
    border-bottom: 2px solid black;
    font-weight: 700;
  }
  .details-1 {
    border-bottom: 2px solid black;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    padding: 0;
  }

  th {
    text-align: center;
    white-space: nowrap;
    font-weight: 600;
    font-size: medium;
    color: black;
    padding-top: 0;
    padding-bottom: 0;
  }
  td {
    text-align: start;
    white-space: nowrap;
    font-weight: 600;
    font-size: medium;
    color: black;
    padding-top: 0;
    padding-bottom: 0;
  }
  .proc-detail {
    border-top: 2px solid black;
  }
  .btn-info {
    @media print {
      display: none;
    }
  }
  .no-print {
    @media print {
      display: none;
    }
  }
  .preparedBy {
    font-weight: bolder;
    font-size: medium;
  }
  .sealimg:not(img) {
    border: 0 !important;
  }
  .signimg:not(img) {
    border: 0 !important;
  }
  .second-th {
    padding-left: 30%;
  }
`;
