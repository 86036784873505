import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const ClinicExamin = () => {
  const { uhid } = useParams();
  const user = useSelector((state) => state.user.currentUser);
  const token = user.token;
  const branch = user.branch_name;
  const [exmData, setExmData] = useState([]);

  const getExamineDetails = async () => {
    try {
      const respsData = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/doctor/getDentalPatientDataByID/${uhid}/${user?.hosId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExmData(respsData.data);
      console.log(respsData.data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(exmData);
  useEffect(() => {
    getExamineDetails();
  }, []);
  return (
    <Wrapper>
      <div className="table cont-box">
        <div
          className="widget-area-2 proclinic-box-shadow mx-3 mt-5"
          id="tableres"
        >
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Diagnosis Category</th>
                  <th>Disease</th>
                  <th>Chief Complaint</th>
                  <th>Tooth</th>
                  <th>On Examination</th>
                  <th>Advice</th>
                </tr>
              </thead>
              <tbody>
                {exmData?.map((item) => (
                  <>
                    <tr>
                      {/* <td>{item.examin_date?.split("T")[0]}</td> */}
                      <td>{item.date?.split(" ")[0]}</td>
                      <td>{item.diagnosis_category}</td>
                      <td>{item.disease}</td>

                      <td>{item.chief_complain}</td>
                      <td>{item.selected_teeth}</td>

                      <td>{item.on_examination}</td>
                      <td>{item.advice}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ClinicExamin;
const Wrapper = styled.div`
  .table {
    @media screen and (max-width: 768px) {
      width: 22rem;
      margin-left: -0.1rem;
    }
  }

  .cont-box {
    width: 100%;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }
`;
