import { Route, Routes, useNavigate } from "react-router-dom";
import DoctorDashboard from "./pages/DoctorPage/DoctorDashboard";
import ProfileDashboard from "./components/Doctor/Profile/ProfileDashboard";
import PatientProfile from "./pages/DoctorPage/PatientProfile";
import ExaminationDashBoard from "./components/Doctor/Examination/ExaminationDashBoard";
import ExaminationDashBoardPatient from "./components/Doctor/Examination/ExaminationDashBoardPatient";
import ExaminationDashBoardPediatric from "./components/Doctor/Examination/ExaminationDashBoardPediatric";
import TreatmentDashBoard from "./components/Doctor/Treatment/TreatmentDashBoard";
import PrescriptionDashBoard from "./components/Doctor/Prescription/PrescriptionDashBoard";
import TPrescription from "./components/Doctor/Prescription/TreatmentPrescription/TPrescription";
import TPrescriptionDash from "./components/Doctor/Prescription/TreatmentPrescription/TPrescriptionDash";
import DoctorLogin from "./components/Doctor/Login/DoctorLogin";
import NewTreatment from "./components/Doctor/Treatment/NewTreatment";
import TreatmentForm from "./components/Doctor/Treatment/TreatmentForm";
import TreatmentDashTwo from "./components/Doctor/Treatment/TreatmentDashTwo";
import NewTreatPrescription from "./components/Doctor/Prescription/TreatmentPrescription/NewTreatPrescription";
import ViewTreatPrescription from "./components/Doctor/Prescription/TreatmentPrescription/ViewTreatPrescription";
import TreatSuggestDashs from "./components/Doctor/Treatment Suggestion/TreatSuggestDashs";
import SecurityAmount from "./components/Doctor/Treatment Suggestion/SecurityAmount";
import PrintSecurityAmt from "./components/Doctor/Treatment Suggestion/PrintSecurityAmt";
import { useDispatch, useSelector } from "react-redux";
import PatientBillsByTpid from "./pages/PatientBills/PatientBillsByTpid";
import AttendanceLeave from "./pages/DoctorPage/AttendanceLeave";
import PatintDuePaymentPrint from "./pages/PatientBills/PatintDuePaymentPrint";
import PasswordReset from "./pages/PasswordReset";
import ScrollToTop from "./components/ScrollToTop";
import Print_Oral_Blood from "./pages/DoctorPage/Print_Oral_Blood";
import { useEffect, useState } from "react";
import axios from "axios";
import { clearUser, toggleTableRefresh } from "./redux/user/userSlice";
import animationData from "./animation/animation-four.json";
import Lottie from "lottie-react";
import PrescriptionDetails from "./pages/DoctorPage/PrescriptionDetails";
import ViewTreatPrescriptionList from "./components/Doctor/Prescription/TreatmentPrescription/ViewTreatPrescriptionList";
import PrescriptionQuick from "./pages/DoctorPage/PrescriptionQuick";
import SittingBill from "./components/Doctor/Treatment/SittingBill";
import QPrescriptionForm from "./components/Doctor/Prescription/Quick Prescription/QPrescriptionForm";
import ViewQuickPrescription from "./pages/DoctorPage/ViewQuickPrescription";
import Receptioinstdash from "./components/receptionist/pages/receptionist/Receptioinstdash";
import AllPatient from "./components/receptionist/pages/receptionist/AllPatient";
import EditPopup from "./components/receptionist/components/receptionist/Appointment/EditPopup";
import ModifyPopup from "./components/receptionist/components/receptionist/Appointment/ModifyPopup";
import DeletePopup from "./components/receptionist/components/receptionist/Appointment/DeletePopup";
import Doctorprofile from "./components/receptionist/components/receptionist/DoctorSection/Doctorprofile";
import PrintOpdBill from "./components/receptionist/components/receptionist/ReceptioinstDashboard/PrintOpdBill";
import Receptionistdash from "./components/receptionist/pages/receptionist/Receptioinstdash";
import AppointmentSection from "./components/receptionist/pages/receptionist/AppointmentSection";
import BillSection from "./components/receptionist/pages/receptionist/BillSection";
import DoctorSection from "./components/receptionist/pages/receptionist/DoctorSection";
import VideoSection from "./components/receptionist/pages/receptionist/VideoSection";
import LabSection from "./components/receptionist/pages/receptionist/LabSection";
import ReportSection from "./components/receptionist/pages/receptionist/ReportSection";
import NewPatient from "./components/receptionist/pages/receptionist/NewPatient";
import Inquiry from "./components/receptionist/pages/receptionist/Inquiry";
import OpdCollection from "./components/receptionist/pages/receptionist/OpdColletion";
import Profile from "./components/receptionist/pages/receptionist/Profile";
import BranchInfo from "./components/receptionist/pages/receptionist/BranchInfo";
import F404page from "./components/receptionist/pages/receptionist/F404page";
import SittingBillPayment from "./components/receptionist/pages/receptionist/SittingBillPayment";
import AllCreditInvoice from "./components/receptionist/pages/receptionist/AllCreditInvoice";
import FinalInvoices from "./components/receptionist/pages/receptionist/FinalInvoices";
import CreditPatientBillsByTpid from "./components/receptionist/pages/receptionist/CreditPatientBillsByTpid";
import CreditSittingBill from "./components/receptionist/pages/receptionist/CreditSittingBill";
import ReceptionSittingBill from "./components/receptionist/pages/receptionist/ReceptionSittingBill";
import ClinicSetting from "./components/superAdmin/ClinicSetting";
import ManageStaff from "./components/superAdmin/ManageStaff";
import ClinicConfigSetting from "./components/superAdmin/ClinicConfigSetting";
import TreatmentSetting from "./components/superAdmin/TreatmentSetting";
import CalenderSetting from "./components/superAdmin/CalenderSetting";
import EmployeeProfile from "./components/superAdmin/EmployeeProfile";
import Branches from "./components/superAdmin/Branches";
import styled from "styled-components";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.currentUser);
  const [todayAttendance, setTodayAttendance] = useState([]);
  console.log(user);
  const date = new Date().toISOString().slice(0, 10);
  const [loading, setLoading] = useState(false);

  const logoutHandleByToken = () => {
    dispatch(clearUser());
    navigate("/");
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getTodayAttendance = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/doctor/getTodayAttendance/${user?.branch_name}/${user?.employee_ID}/${date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      setTodayAttendance(response?.data?.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data.message;
        if (errorMessage === "Unauthorized - Token expired") {
          logoutHandleByToken();
        } else {
          console.log("Unauthorized access:", errorMessage);
        }
      } else {
        console.log("An error occurred:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // getTodayAttendance();
  }, []);

  console.log(todayAttendance);

  return (
    <>
      <Styling>
        <ScrollToTop />

        <Routes>
          <Route
            path="/"
            element={user === null ? <DoctorLogin /> : <Receptioinstdash />}
          />
          <Route
            path="/dashboard"
            element={user === null ? <DoctorLogin /> : <Receptioinstdash />}
          />

          <Route
            path="/AllPatient"
            element={user === null ? <DoctorLogin /> : <AllPatient />}
          />
          <Route
            path="/doctor-dashboard"
            element={user === null ? <DoctorLogin /> : <DoctorDashboard />}
          />

          <Route
            path="/examination-Dashboard/:id/:uhid"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <ExaminationDashBoard />
              )
            }
          />
          <Route
            path="/ExaminationDashBoardPatient/:id/:dcat/:tpid"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <ExaminationDashBoardPatient />
              )
            }
          />
          <Route
            path="/ExaminationDashBoardPediatric/:id/:dcat/:tpid"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <ExaminationDashBoardPediatric />
              )
            }
          />
          <Route
            path="/treatmentSuggestion/:id/:tpid"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <TreatSuggestDashs />
              )
            }
          />
          <Route
            path="/SecurityAmount/:id/:tpid"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <SecurityAmount />
              )
            }
          />
          <Route
            path="/print-security-bill/:sa_id/:tpid"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <PrintSecurityAmt />
              )
            }
          />
          <Route
            path="/TreatmentDashBoard/:tpid/:appoint_id"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <TreatmentDashBoard />
              )
            }
          />
          <Route
            path="/NewTreatment/:id/:tpid"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <NewTreatment />
              )
            }
          />
          <Route
            path="/treatProducer/:id/:appointment_id"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <TreatmentForm />
              )
            }
          />
          <Route
            path="/treatmentDashTwo/:tsid/:appoint_id/:tp_id/:treatment"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <TreatmentDashTwo />
              )
            }
          />
          <Route
            path="/NewTreatPrescription/:id"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <NewTreatPrescription />
              )
            }
          />
          <Route
            path="/PrescriptionDashBoard"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <PrescriptionDashBoard />
              )
            }
          />
          <Route
            path="/TPrescription"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <TPrescription />
              )
            }
          />
          <Route
            path="/TPrescriptionDash/:tsid/:appoint_id/:tpid/:sitting/:treatment"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <TPrescriptionDash />
              )
            }
          />
          <Route
            path="/ViewTreatPrescription/:tpid/:appoint_id/:sitting/:treatment"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <ViewTreatPrescription />
              )
            }
          />

          <Route
            path="/ViewTreatPrescriptionlist/:tpid/:appoint_id/:sitting/:treatment"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <ViewTreatPrescriptionList />
              )
            }
          />

          <Route
            path="/ViewPatientTotalBill/:tpid"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <PatientBillsByTpid />
              )
            }
          />

          <Route
            path="/profileDashboard"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <ProfileDashboard />
              )
            }
          />
          <Route
            path="/Patient-profile/:uhid"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <PatientProfile />
              )
            }
          />
          <Route
            path="/all-patient"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <AllPatient />
              )
            }
          />

          <Route
            path="/attendance-dashboard"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <AttendanceLeave />
              )
            }
          />

          <Route
            path="/patient-due-payment-print/:tpid"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <PatintDuePaymentPrint />
              )
            }
          />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route
            path="/print-oral-testing/:id"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <Print_Oral_Blood />
              )
            }
          />

          <Route
            path="/prescription-details"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <PrescriptionDetails />
              )
            }
          />

          <Route
            path="/prescription-generate/:tpid/:appoint_id"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <PrescriptionQuick />
              )
            }
          />

          <Route
            path="/view-quick-prescription/:uhid/:appointId"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <ViewQuickPrescription />
              )
            }
          />

          <Route
            path="/ViewPatientSittingBill/:tpid/:sitting/:appoint_id/:treatment"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <SittingBill />
              )
            }
          />

          <Route
            path="/Quick-Prescription/:appoint_id/:patient_uhid"
            element={
              user === null ? (
                <DoctorLogin />
              ) : loading ? (
                <>
                  {" "}
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    style={{ background: "transparent" }}
                  ></Lottie>
                </>
              ) : (
                <QPrescriptionForm />
              )
            }
          />

          <Route
            path="/receptionist-dashboard"
            element={user === null ? <DoctorLogin /> : <Receptionistdash />}
          />
          <Route
            path="/all_patient"
            element={user === null ? <DoctorLogin /> : <AllPatient />}
          />
          <Route
            path="/inquiry"
            element={user === null ? <DoctorLogin /> : <Inquiry />}
          />
          <Route
            path="/patient_profile/:uhid"
            element={user === null ? <DoctorLogin /> : <PatientProfile />}
          />
          <Route
            path="/print_Opd_Reciept/:appointmentId"
            element={user === null ? <DoctorLogin /> : <PrintOpdBill />}
          />
          <Route
            path="/print_security_amount/:SId"
            element={user === null ? <DoctorLogin /> : <PrintSecurityAmt />}
          />
          <Route
            path="/appointment"
            element={user === null ? <DoctorLogin /> : <AppointmentSection />}
          />
          <Route
            path="/edit_appointment"
            element={user === null ? <DoctorLogin /> : <EditPopup />}
          />
          <Route
            path="/modify_appointment"
            element={user === null ? <DoctorLogin /> : <ModifyPopup />}
          />
          <Route
            path="/delete_appointment"
            element={user === null ? <DoctorLogin /> : <DeletePopup />}
          />
          <Route
            path="/bill_section"
            element={user === null ? <DoctorLogin /> : <BillSection />}
          />
          <Route
            path="/DoctorSection"
            element={user === null ? <DoctorLogin /> : <DoctorSection />}
          />
          <Route
            path="/doctor_profile"
            element={user === null ? <DoctorLogin /> : <Doctorprofile />}
          />
          <Route
            path="/video"
            element={user === null ? <DoctorLogin /> : <VideoSection />}
          />
          <Route
            path="/lab"
            element={user === null ? <DoctorLogin /> : <LabSection />}
          />
          <Route
            path="/report"
            element={user === null ? <DoctorLogin /> : <ReportSection />}
          />
          <Route
            path="/new_patient"
            element={user === null ? <DoctorLogin /> : <NewPatient />}
          />
          <Route
            path="/opd_collection"
            element={user === null ? <DoctorLogin /> : <OpdCollection />}
          />
          <Route
            path="/receptionist_profile"
            element={user === null ? <DoctorLogin /> : <Profile />}
          />
          <Route
            path="/security_amount"
            element={user === null ? <DoctorLogin /> : <SecurityAmount />}
          />

          <Route
            path="/invoices"
            element={user === null ? <DoctorLogin /> : <FinalInvoices />}
          />

          <Route
            path="/all_credit_invoice"
            element={user === null ? <DoctorLogin /> : <AllCreditInvoice />}
          />
          <Route
            path="/PatintDuePaymentPrint/:bid/:tpid/:uhid"
            element={
              user === null ? <DoctorLogin /> : <PatintDuePaymentPrint />
            }
          />
          <Route
            path="/SittingBillPayment/:sbid/:tpid/:uhid"
            element={user === null ? <DoctorLogin /> : <SittingBillPayment />}
          />
          <Route
            path="/patient-bill/:billid/:tpid"
            element={user === null ? <DoctorLogin /> : <PatientBillsByTpid />}
          />
          <Route
            path="/credit-patient-bill/:billid/:tpid"
            element={
              user === null ? <DoctorLogin /> : <CreditPatientBillsByTpid />
            }
          />
          <Route
            path="/branch-details"
            element={user === null ? <DoctorLogin /> : <BranchInfo />}
          />

          <Route
            path="/ViewPatientSittingBill/:tpid/:sbid/:treatment"
            element={user === null ? <DoctorLogin /> : <ReceptionSittingBill />}
          />
          <Route
            path="/ViewCreditPatientSittingBill/:tpid/:sbid"
            element={user === null ? <DoctorLogin /> : <CreditSittingBill />}
          />

          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="*" element={<F404page />} />

          <Route
            path="/clinic-setting"
            element={user === null ? <DoctorLogin /> : <ClinicSetting />}
          />

          <Route
            path="/calender-setting"
            element={user?.id === null ? <DoctorLogin /> : <CalenderSetting />}
          />

          <Route
            path="/manage-staff"
            element={user === null ? <DoctorLogin /> : <ManageStaff />}
          />
          <Route
            path="/clinic-config-setting"
            element={user === null ? <DoctorLogin /> : <ClinicConfigSetting />}
          />
          <Route
            path="/treatment-setting"
            element={user === null ? <DoctorLogin /> : <TreatmentSetting />}
          />

          <Route
            path="/employee-profile/:eid"
            element={user === null ? <DoctorLogin /> : <EmployeeProfile />}
          />
          <Route
            path="/branch"
            element={user === null ? <DoctorLogin /> : <Branches />}
          />
        </Routes>
      </Styling>
    </>
  );
}

export default App;
const Styling = styled.div``;
