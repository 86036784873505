import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GiFastBackwardButton } from "react-icons/gi";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleTableRefresh } from "../../../redux/user/userSlice";
import SuggestedtreatmentList from "../Examination/SaveExaminationData/SuggestedtreatmentList";
import cogoToast from "cogo-toast";
import { IoMdAdd } from "react-icons/io";
import { FaPrescriptionBottleMedical } from "react-icons/fa6";

const TreatSuggest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [secBut, setSecBut] = useState(false);
  const [loadingTestBt, setLoadingTestBt] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const branchData = useSelector((state) => state.branch.currentBranch);
  console.log(branchData);
  const branch = user.branch_name;
  const employeeName = user.employee_name;
  const [otherMed, setOtherMed] = useState("");
  const token = user.token;
  console.log(branch);
  const { id, tpid } = useParams();
  console.log(useParams());
  const { refreshTable } = useSelector((state) => state.user);
  const [treatments, setTreatments] = useState([]);
  const [getPatientData, setGetPatientData] = useState([]);
  const [getTreatMedicine, setGetTreatMedicine] = useState([]);
  const [getTreatData, setGetTreatData] = useState([]);
  const [procedureTreat, setProcedureTreat] = useState([]);
  const [medicineOptions, setMedicineOptions] = useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(0);
  const [treatList, setTreatList] = useState([]);
  const [formData, setFormData] = useState({
    appoint_id: id,
    branch: branch,
    p_uhid: "",
    tp_id: tpid,
    disease: "",
    treatment_procedure: "",
    treatment_name: "",
    total_sitting: "",
    total_cost: "",
  });
  const [prescriptionData, setPrescriptionData] = useState({
    branch_name: branch,
    patient_uhid: "",
    disease: "",
    treatment: "",
    medicine_name: "",
    dosage: "",
    frequency: "",
    duration: "",
    note: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevInputItem) => ({
      ...prevInputItem,
      [name]: value,
    }));

    setFormData((prevInputItem) => {
      const treatment = treatments.find(
        (treatment) => treatment.treatment_name === prevInputItem.treatment_name
      );

      if (branchData[0]?.hospital_category === "Nabh") {
        return {
          ...prevInputItem,
          total_cost: treatment?.nabh,
        };
      } else {
        return {
          ...prevInputItem,
          total_cost: treatment?.non_nabh,
        };
      }
    });
  };

  const handleChangeMed = (e) => {
    const { name, value } = e.target;
    setPrescriptionData({ ...prescriptionData, [name]: value });
  };

  const updateAppointmentData = async () => {
    try {
      const res = await axios.put(
        `https://dentalguru-lite.dentalguru.software/api/doctor/updateAppointmentPath/${id}/${branch}/${user?.hosId}`,
        {
          currentPath: `/treatmentSuggestion/${id}/${tpid}`,
          tpid: tpid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      const { data } = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/doctor/getDentalDataByID/${id}/${tpid}/${user?.hosId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const diseases = new Set(
    data.flatMap((entry) =>
      entry.disease.split(", ").map((disease) => disease.trim())
    )
  );

  const uniqueDiseases = [...diseases];

  console.log(uniqueDiseases);

  const getTreatmentList = async () => {
    try {
      const { data } = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/doctor/treatmentLists`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      setTreatments(data.data);
    } catch (error) {
      console.log("Error fetching treatments:", error);
    }
  };

  console.log(treatments);

  const getProcedureTreat = async () => {
    try {
      const { data } = await axios.get(
        "https://dentalguru-lite.dentalguru.software/api/doctor/getProcedureList",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      setProcedureTreat(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTreatmentList();
    getProcedureTreat();
  }, []);

  const getPatientDetail = async () => {
    try {
      const { data } = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/doctor/getAppointmentsWithPatientDetailsById/${tpid}/${user?.hosId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setGetPatientData(data);
      console.log(data);

      const uhid = data[0]?.uhid;
      setFormData((prevState) => ({
        ...prevState,
        p_uhid: uhid || "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPatientDetail();
  }, [refreshTable]);

  const timelineForTreatSuggest = async () => {
    try {
      const response = await axios.post(
        "https://dentalguru-lite.dentalguru.software/api/doctor/insertTimelineEvent",
        {
          type: "Treatment Suggest",
          description: `Select Treatment : ${formData.treatment_name} for disease : ${formData.disease}`,
          branch: branch,
          patientId: getPatientData.length > 0 ? getPatientData[0].uhid : "",
          hostId: user?.hosId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const calculateTotalCost = () => {
    let uniqueTreatments = formData.treatment_name;

    const treatment = treatments.find(
      (treatment) => treatment.treatment_name === uniqueTreatments
    );

    return treatment?.treatment_cost;
  };

  console.log(getPatientData);

  useEffect(() => {
    const calculatedValue = calculateTotalCost();
    setValue(calculatedValue);
  }, []);

  const forms = {
    appoint_id: id,
    hostId: user?.hosId,
    branch: branch,
    p_uhid: getPatientData[0]?.uhid,
    tp_id: tpid,
    desease: formData.disease,
    treatment_procedure: formData.treatment_procedure,
    treatment_name: formData.treatment_name,
    totalCost: formData.total_cost,
    total_sitting: formData.total_sitting,
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(
        `https://dentalguru-lite.dentalguru.software/api/doctor/insertTreatSuggest`,
        forms,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setLoading(false);
      cogoToast.success("successfully added");
      console.log(res.data);
      updateAppointmentData();
      timelineForTreatSuggest();
      setSecBut(true);
      setFormData({
        ...formData,
        disease: "",
        treatment_procedure: "",
        treatment_name: "",
        total_sitting: "",
        total_cost: "",
      });
      dispatch(toggleTableRefresh());
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(error.response.data.message);
    }
  };

  const handleChangePage = () => {
    // navigate(`/TreatmentDashBoard/${tpid}/${id}`);
    navigate(`/prescription-generate/${tpid}/${id}`);
  };

  const getListTreatment = async () => {
    try {
      const { data } = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/doctor/getTreatList/${branch}/${tpid}/${user?.hosId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      setTreatList(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListTreatment();
  }, [refreshTable]);

  console.log(treatList);

  const treatlistFilter = treatList?.filter((item) => {
    return item.desease === prescriptionData?.disease;
  });

  console.log(treatlistFilter);

  const getTreatDetails = async () => {
    try {
      const { data } = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/doctor/getTreatmentDataList/${tpid}/${branch}/${user?.hosId}`
      );
      setGetTreatData(data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(getTreatData);

  const fetchMedicineOptions = async () => {
    try {
      const { data } = await axios.get(
        "https://dentalguru-lite.dentalguru.software/api/doctor/getMedicineData",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMedicineOptions(data);
    } catch (error) {
      console.error("Error fetching medicine options:", error);
    }
  };

  useEffect(() => {
    getTreatDetails();
    fetchMedicineOptions();
  }, []);

  console.log(getTreatData);

  const addNewMedicine = async () => {
    try {
      const response = await axios.post(
        `https://dentalguru-lite.dentalguru.software/api/doctor/purchaseInventory/${branch}`,
        {
          item_name: otherMed,
          item_category: "drugs",
          branch_name: branch,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);

      cogoToast.success("New medicine added successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const medicineInput = {
    branch_name: branch,
    patient_uhid: getPatientData[0]?.uhid,
    disease: prescriptionData?.disease,
    treatment: prescriptionData?.treatment,
    medicine_name:
      prescriptionData?.medicine_name === "other"
        ? otherMed
        : prescriptionData?.medicine_name,
    dosage: prescriptionData?.dosage,
    frequency: prescriptionData?.frequency,
    duration: prescriptionData?.duration,
    note: prescriptionData?.note,
  };

  console.log(medicineInput);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `https://dentalguru-lite.dentalguru.software/api/doctor/insertTreatPrescriptionQuick/${tpid}/${user?.hosId}`,
        medicineInput,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      setLoading(false);
      addNewMedicine();
      setPrescriptionData({
        disease: "",
        treatment: "",
        medicine_name: "",
        dosage: "",
        frequency: "",
        duration: "",
        note: "",
      });
      dispatch(toggleTableRefresh());
    } catch (error) {
      setLoading(false);
      cogoToast.error("Error:", error.response.data);
    }
  };

  const getTreatPrescriptionByAppointId = async () => {
    try {
      const { data } = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/doctor/getTreatPrescriptionByAppointIdList/${tpid}/${user?.hosId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setGetTreatMedicine(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(getTreatMedicine);

  useEffect(() => {
    getTreatPrescriptionByAppointId();
  }, [refreshTable]);

  const handledelete = async (id) => {
    console.log(id);
    try {
      const confirmed = window.confirm("Are you sure you want to delete?");

      if (confirmed) {
        const res = await axios.delete(
          `https://dentalguru-lite.dentalguru.software/api/doctor/deleteTreatPrescriptionById/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(res.data);

        getTreatPrescriptionByAppointId();
      }
    } catch (error) {
      console.log(error);
      cogoToast.error("An error occurred while deleting the item.");
    }
  };

  const totalTreatSuggest = () => {
    try {
      let total = 0;
      treatList.forEach((item) => {
        total = total + parseFloat(item.totalCost);
      });
      console.log(total);
      return total;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };
  const grandTotal = totalTreatSuggest();

  const generatePres = () => {
    // alert("745");
    navigate(`/prescription-generate/${tpid}/${id}`);
  };

  return (
    <>
      <Wrapper>
        <div className="container-fluid">
          <div className="container-fluid main">
            <div className="d-flex justify-content-start align-items-center">
              <button
                className="btn btn-secondary mb-2"
                onClick={() => window.history.back()}
              >
                <GiFastBackwardButton size={22} />
              </button>
              <h5 className="mx-2">{data[0]?.diagnosis_category}</h5>
            </div>
            <p className="fs-1 p-3 rounded text-center">Treatment Suggestion</p>
          </div>
          <div className="container-fluid patient">
            <div className="row shadow-sm p-3 mb-3 bg-body rounded">
              {getPatientData.map((item, index) => (
                <>
                  <div key={index} className="col-lg-12 ">
                    <div className="row">
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                        <p>
                          <strong>Treatment PID</strong> : {tpid}
                        </p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                        <p>
                          <strong>Patient Name</strong> : {item.patient_name}
                        </p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                        <p>
                          <strong>Patient Mobile No.</strong> : {item.mobileno}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div key={index + "secondRow"} className="col-lg-12 ">
                    <div className="row">
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                        <p className="mb-0">
                          <strong>Blood Group</strong> : {item.bloodgroup}
                        </p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                        <p className="mb-0">
                          <strong>Disease</strong> : {item.disease}
                        </p>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                        <p className="mb-0">
                          <strong>Allergy</strong> : {item.allergy}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="container-fluid mainbody">
            <div className="row shadow-sm p-2 mb-2 bg-body rounded">
              <form onSubmit={handleSubmitForm}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="">
                        <div className="text-start">
                          <label className="label">Select Disease</label>
                        </div>
                        <select
                          className="form-select text-start w-100"
                          name="disease"
                          aria-label="Default select example"
                          onChange={handleChange}
                          value={formData.disease}
                          required
                        >
                          <option value="">-select disease-</option>
                          {uniqueDiseases.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="">
                        <div className="text-start">
                          <label className="label">Select Procedure</label>
                        </div>
                        <select
                          className="form-select text-start w-100"
                          name="treatment_procedure"
                          aria-label="Default select example"
                          onChange={handleChange}
                          required
                          value={formData.treatment_procedure}
                        >
                          <option value="">-select treatment-</option>
                          {procedureTreat.map((item, index) => (
                            <option key={index}>
                              {item.treat_procedure_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="">
                        <div className="text-start">
                          <label className="label">Select Treatments</label>
                        </div>
                        <select
                          className="form-select text-start w-100"
                          name="treatment_name"
                          aria-label="Default select example"
                          onChange={handleChange}
                          required
                          value={formData.treatment_name}
                        >
                          <option value="">-select treatment-</option>

                          {treatments
                            ?.filter(
                              (item) =>
                                item.treat_procedure_name ===
                                formData.treatment_procedure
                            )
                            .map((item, index) => (
                              <option key={index}>{item.treatment_name}</option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="text-start">
                        <label className="label">Total Cost</label>
                        <input
                          type="text"
                          className="form-control w-100"
                          name="total_cost"
                          required
                          value={
                            formData.treatment_name ? formData.total_cost : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="text-start">
                        <label className="label">Required Sitting</label>
                        <div className="d-flex justify-content-center align-item-center">
                          <input
                            type="number"
                            className="form-control w-100"
                            name="total_sitting"
                            placeholder="Answer...."
                            onChange={handleChange}
                            value={formData.total_sitting}
                            min="1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-12 col-12">
                      <div className="h-100 d-flex justify-content-center align-items-center">
                        <button
                          type="submit"
                          disabled={loading}
                          className="btn btn-color text-light mt-5"
                        >
                          {loading ? "Save..." : "Save"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="container-fluid rounded p-0">
            <SuggestedtreatmentList
              tpid={tpid}
              getPatientData={getPatientData}
            />
          </div>
          <div className="container-fluid">
            <h2>Medicine Details</h2>
            <div className="row  shadow-sm p-3 mb-3 bg-body rounded">
              <form onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                    <div className="form-outline">
                      <label>Disease</label>
                      <select
                        name="disease"
                        onChange={handleChangeMed}
                        required
                        id=""
                        value={prescriptionData.disease}
                        className="form-select text-start w-100"
                      >
                        <option value="">-select Disease-</option>
                        {uniqueDiseases.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                    <div className="form-outline">
                      <label>Treatment</label>
                      <select
                        className="form-select w-100"
                        name="treatment"
                        required
                        aria-label="Default select example"
                        onChange={handleChangeMed}
                        value={prescriptionData.treatment}
                      >
                        <option value="">-select treatment-</option>
                        {treatlistFilter.map((item, index) => (
                          <option key={index} value={item.treatment_name}>
                            {item.treatment_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                    <div className="form-outline">
                      <label>Medicine Name</label>

                      <select
                        className={`form-select w-100`}
                        name="medicine_name"
                        aria-label="Default select example"
                        onChange={handleChangeMed}
                        required
                      >
                        <option value="">-select medicine-</option>
                        <option value="other">-other medicine-</option>
                        {medicineOptions.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {prescriptionData?.medicine_name === "other" && (
                    <>
                      <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                        <label>Other Medicine</label>
                        <input
                          type="text"
                          placeholder="other medicine"
                          className="form-control"
                          required
                          name="otherMed"
                          value={otherMed}
                          onChange={(e) => setOtherMed(e.target.value)}
                        />
                      </div>
                    </>
                  )}

                  <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                    <div data-mdb-input-init className="form-outline">
                      <label>Dosage</label>
                      {prescriptionData?.medicine_name !== "" && (
                        <input
                          type="text"
                          id="dosage"
                          placeholder="dosage"
                          className="form-control"
                          required
                          name="dosage"
                          value={prescriptionData?.dosage}
                          onChange={handleChangeMed}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                    <div data-mdb-input-init className="form-outline">
                      <label>Frequency</label>
                      {prescriptionData?.medicine_name !== "" && (
                        <>
                          <select
                            id="frequency"
                            className="form-control"
                            name="frequency"
                            required
                            value={prescriptionData?.frequency}
                            onChange={handleChangeMed}
                          >
                            <option value="">Choose frequency</option>
                            <option value="1-1-1(TDS)">1-1-1(TDS)</option>
                            <option value="1-1-0(BD)">1-1-0(BD)</option>
                            <option value="0-1-1(BD)">0-1-1(BD)</option>
                            <option value="1-0-1(BD)">1-0-1(BD)</option>
                            <option value="0-0-1(HS)">0-0-1(HS)</option>
                            <option value="0-1-0(OD)">0-1-0(OD)</option>
                            <option value="1-0-0(BM)">1-0-0(BM)</option>
                            <option value="SOS">SOS</option>
                          </select>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                    <div data-mdb-input-init className="form-outline">
                      <label>Duration</label>
                      {prescriptionData?.medicine_name !== "" && (
                        <>
                          <select
                            id="duration"
                            className="form-control"
                            name="duration"
                            required
                            value={prescriptionData.duration}
                            onChange={handleChangeMed}
                          >
                            <option value="">Choose duration</option>
                            <option value="1 day">1 day</option>
                            <option value="2 days">2 days</option>
                            <option value="3 days">3 days</option>
                            <option value="4 days">4 days</option>
                            <option value="5 days">5 days</option>
                            <option value="6 days">6 days</option>
                            <option value="1 week">1 week</option>
                            <option value="2 weeks">2 weeks</option>
                            <option value="3 weeks">3 weeks</option>
                            <option value="1 Month">1 Month</option>
                            <option value="3 Months">3 Months</option>
                          </select>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                    <div data-mdb-input-init className="form-outline">
                      <label>Note</label>
                      <textarea
                        type="text"
                        id="note"
                        className="form-control"
                        placeholder="write note"
                        name="note"
                        value={prescriptionData?.note}
                        onChange={handleChangeMed}
                      />
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <button
                        className="btn btn-color fs-5 mt-4 text-white shadow"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? "Add..." : "Add"}
                        <IoMdAdd size={20} />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <table class="table">
                <thead className="rounded">
                  <tr>
                    <th className="colorth">Date</th>
                    <th className="colorth">Medicine Name</th>
                    <th className="colorth">Disease</th>
                    <th className="colorth">Dosage</th>
                    <th className="colorth">Frequency</th>
                    <th className="colorth">Duration</th>
                    <th className="colorth">Note</th>
                    <th className="colorth">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {getTreatMedicine?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.date?.split(" ")[0]}</td>
                      <td>{item.medicine_name}</td>
                      <td>{item.desease}</td>
                      <td>{item.dosage}</td>
                      <td>{item.frequency}</td>
                      <td>{item.duration}</td>
                      <td>{item.note}</td>
                      <td>
                        <button
                          className="btn btn-color text-white shadow"
                          style={{
                            backgroundColor: "#0dcaf0",
                            border: "#0dcaf0",
                          }}
                          onClick={() => handledelete(item.id)}
                        >
                          <FaPrescriptionBottleMedical size={22} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            {treatList.length > 0 ? (
              <>
                <button
                  className="btn btn-color text-light mx-2 shadow fw-bold"
                  onClick={handleChangePage}
                >
                  Start Treatment
                </button>
              </>
            ) : (
              <>
                {branchData[0]?.doctor_payment === "Yes" ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <button
                      type="button"
                      className="btn btn-color text-light shadow fw-bold"
                      disabled
                    >
                      Print Prescription
                    </button>
                  </>
                )}

                <button
                  className="btn btn-color text-light mx-2 shadow fw-bold"
                  disabled
                >
                  Start Treatment
                </button>
              </>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default TreatSuggest;
const Wrapper = styled.div`
  .main {
    margin-top: 1rem;
    width: 100% !important;
    @media (min-width: 767px) and (max-width: 913px) {
      width: 100%;
    }
    @media (min-width: 992px) and (max-width: 1024px) {
      width: 100%;
    }
  }
  .mainbody {
    @media (min-width: 767px) and (max-width: 913px) {
      width: 100%;
    }
    @media (min-width: 992px) and (max-width: 1024px) {
      width: 100%;
    }
  }
  .form {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0 20px 0;
  }
  .label {
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0.5rem 0rem;
  }
  .colorth {
    background: #008080;
    color: white;
  }
  .btn-color {
    background-color: rgb(8 145 178);
  }
`;
