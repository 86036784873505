import axios from "axios";
import { numToWords } from "num-to-words";
import React, { useEffect, useRef, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { FaPrint } from "react-icons/fa6";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { SiGmail, SiGooglemessages } from "react-icons/si";
import { IoLogoWhatsapp } from "react-icons/io";
import cogoToast from "cogo-toast";

const ReceptionSittingBill = () => {
  const { tpid, sbid } = useParams();
  const contentRef = useRef();
  const navigate = useNavigate();
  const [getPatientData, setGetPatientData] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const { currentBranch } = useSelector((state) => state.branch);
  console.log(currentBranch);
  const token = currentUser?.token;
  const branch = currentUser?.branch_name;
  const user = currentUser;
  const [getExaminData, setGetExaminData] = useState([]);
  const [getBranch, setGetBranch] = useState([]);
  const [getLabData, setGetLabData] = useState([]);
  const [sittingBill, setSittingBill] = useState([]);
  const [getDocDetails, setGetDocDetails] = useState([]);

  const getBranchDetails = async () => {
    try {
      const { data } = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/v1/receptionist/getBranchDetailsByBranch/${branch}/${user?.hosId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      setGetBranch(data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(getBranch);
  const handleButton = async () => {
    try {
      window.print();
    } catch (error) {
      console.log("Error updating sitting count", error);
    }
  };

  // Get Patient Details START
  const getPatientDetail = async () => {
    try {
      const { data } = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/doctor/getAppointmentsWithPatientDetailsById/${tpid}/${user?.hosId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setGetPatientData(data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(getPatientData);

  const getLabAllData = async () => {
    try {
      const res = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/v1/receptionist/lab-details/${tpid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setGetLabData(res.data.lab_details);
      console.log(res.data.lab_details);
    } catch (error) {
      console.log(error);
    }
  };

  const getSittingBillbyId = async () => {
    try {
      const { data } = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/v1/receptionist/getSittingBillDueBySittingId/${branch}/${sbid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data, "get here");
      setSittingBill(data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(sittingBill);

  const getDoctorDetails = async () => {
    try {
      const { data } = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/v1/receptionist/getEmployeeDetailsbyId/${branch}/${getPatientData[0]?.doctor_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setGetDocDetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(getDocDetails);

  const getExamineDetails = async () => {
    try {
      const res = await axios.get(
        `https://dentalguru-lite.dentalguru.software/api/doctor/getDentalDataByTpid/${tpid}/${branch}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setGetExaminData(res.data.result);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(getExaminData);

  useEffect(() => {
    getPatientDetail();
    getBranchDetails();
    getLabAllData();
    getSittingBillbyId();
    getExamineDetails();
  }, []);

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);

    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, window.location.href);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  useEffect(() => {
    getDoctorDetails();
  }, [getPatientData]);

  console.log(sittingBill);

  const goBack = () => {
    navigate("/invoices?tab=paidSittingBill");
  };
  const handleDownloadPdf = async () => {
    const element = contentRef.current;
    const canvas = await html2canvas(element, { scale: 2 });
    const imgData = canvas.toDataURL("image/jpeg", 0.75);

    const pdf = new jsPDF();
    const imgWidth = 210;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, undefined, "FAST");
    pdf.save("sitting bill.pdf");
  };

  const sendPrescriptionMail = async () => {
    const email = getPatientData[0]?.emailid;
    if (!email) {
      alert("Email id not available");
      return;
    }

    const element = contentRef.current;
    if (!element) {
      alert("Content not found for PDF generation.");
      return;
    }

    try {
      const canvas = await html2canvas(element, { scale: 1.5 });
      const imgData = canvas.toDataURL("image/jpeg", 0.5);

      const pdf = new jsPDF();
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "JPEG", 10, 10, imgWidth, imgHeight);
      const pdfData = pdf.output("blob");

      console.log("PDF size:", pdfData.size, "bytes");

      const patient = getPatientData[0];
      const clinic = currentBranch[0];

      const formData = new FormData();
      formData.append("email", email);
      formData.append("patient_name", patient.patient_name);
      formData.append(
        "subject",
        `${patient.patient_name}, your sitting bill file`
      );
      formData.append(
        "textMatter",
        `
        Dear ${patient.patient_name},
  
        Please find the attached sitting bill file.
  
        Clinic Details:
        Name: ${clinic.hospital_name}
        Contact: ${clinic.branch_contact}
        Address: ${clinic.branch_address}
        Email: ${clinic.branch_email}
  
        Thank you for choosing ${clinic.hospital_name}.
  
        Best regards,
        ${clinic.hospital_name} Team
      `
      );
      formData.append("file", pdfData, "prescription.pdf");

      console.log("Form data prepared:", Array.from(formData.entries()));

      cogoToast.info("Sending sitting bill to email...");

      const response = await axios.post(
        "https://dentalguru-lite.dentalguru.software/api/doctor/prescriptionOnMail",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        cogoToast.success("Sitting bill sent successfully");
        console.log("PDF sent successfully:", response.data);
      } else {
        cogoToast.error(`Failed to send PDF: ${response.statusText}`);
        console.error("Error response:", response);
      }
    } catch (error) {
      console.error("Error sending PDF:", error);
      cogoToast.error("Error sending sitting bill");
    }
  };

  const sendPrescriptionWhatsapp = async () => {
    try {
      const element = contentRef.current;
      const canvas = await html2canvas(element, { scale: 2 });
      const imgData = canvas.toDataURL("image/jpeg", 0.75);
      const pdf = new jsPDF();
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        imgWidth,
        imgHeight,
        undefined,
        "FAST"
      );
      const pdfData = pdf.output("blob");
      console.log(pdfData);

      const formData = new FormData();
      formData.append("phoneNumber", getPatientData[0]?.mobileno);
      formData.append("message", "test message");
      const file = new File([pdfData], "sitting bill.pdf", {
        type: "application/pdf",
      });

      formData.append("media_url", file);
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }

      const res = await axios.post(
        "https://dentalguru-lite.dentalguru.software/api/v1/receptionist/sendWhatsapp",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      cogoToast.success("sitting bill sent successfully");
      console.log("PDF sent successfully");
    } catch (error) {
      console.error("Error sending PDF:", error);
      cogoToast.error("Error to send Sitting bill");
    }
  };

  const formDetails = {
    phoneNumber: getPatientData[0]?.mobileno,
    message: `Dear ${getPatientData[0]?.patient_name}, your bill generated for the sitting ${sittingBill[0]?.sitting_number} of treatment ${sittingBill[0]?.treatment}, sitting bill amount is ${sittingBill[0]?.sitting_amount}/-`,
  };
  const billDetailsSms = async () => {
    try {
      const { data } = await axios.post(
        "https://dentalguru-lite.dentalguru.software/api/v1/receptionist/sendSMS",
        formDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      cogoToast.success("Bill details sent successfully");
    } catch (error) {
      console.log(error);
      cogoToast.error("Error to send Sitting bill Details");
    }
  };

  return (
    <>
      <Wrapper>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center my-2 px-3 gap-2">
            <button
              className="btn btn-info no-print btn-lg shadow"
              onClick={goBack}
            >
              <IoMdArrowRoundBack /> Back
            </button>
            <button
              className="btn btn-info no-print btn-lg shadow"
              onClick={handleButton}
            >
              <FaPrint /> Print
            </button>
          </div>
          <div ref={contentRef}>
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="clinic-logo">
                  <img
                    src={getBranch[0]?.head_img}
                    alt="header"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="text-center">
              <h3>Invoice</h3>
            </div>
            <div className="container-fluid">
              <div className="heading-title">
                <h6>Patient Details :</h6>
              </div>
              <h6 className="">
                Patient Type : {getPatientData[0]?.patient_type}
              </h6>
              <table className="table table-bordered border">
                <tbody>
                  {getPatientData?.map((item, index) => (
                    <React.Fragment key={index}>
                      {item?.patient_type === "Credit" && (
                        <tr>
                          <th scope="row">Credit By</th>
                          <td>{item?.credit_By}</td>
                          <th scope="row">Beneficiary Id</th>
                          <td>{item?.beneficiary_Id}</td>
                        </tr>
                      )}
                      <tr>
                        <th scope="row">UHID</th>
                        <td>{item.uhid}</td>
                        <th scope="row">Gender</th>
                        <td>{item.gender}</td>
                      </tr>

                      <tr>
                        <th scope="row">Name</th>
                        <td>{item.patient_name}</td>
                        <th scope="row">Age</th>
                        <td>{item.age}</td>
                      </tr>
                      <tr>
                        <th scope="row">Address</th>
                        <td>{item.address}</td>
                        <th scope="row">Sitting Invoice No.</th>
                        <td>{sbid}</td>
                      </tr>
                      <tr>
                        <th scope="row">Mobile No.</th>
                        <td>{item.mobileno}</td>
                        <th scope="row">Date</th>
                        <td>{sittingBill[0]?.date.split(" ")[0]}</td>
                      </tr>
                      <tr>
                        <th scope="row">Email</th>
                        <td>{item.emailid}</td>
                        <th scope="row">Treatment Package ID</th>
                        <td>{tpid}</td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="container-fluid">
              <div className="heading-title">
                <h6>Doctor Details :</h6>
              </div>
              <div className="d-flex justify-content-between">
                <div className="text-start docDetails">
                  <p>
                    <strong>Doctor Name :</strong> Dr.{" "}
                    {getDocDetails[0]?.employee_name}
                  </p>
                  <p>
                    <strong>Mobile :</strong>{" "}
                    {getDocDetails[0]?.employee_mobile}
                  </p>
                  <p>
                    <strong>Email :</strong> {getDocDetails[0]?.employee_email}
                  </p>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="heading-title">
                <h6>Patient Observation :</h6>
              </div>
              <table className="table table-bordered border">
                <thead>
                  <tr>
                    <th>Seleted Teeth</th>
                    <th>Disease</th>
                    <th>Chief Complain</th>
                    <th>On Exmination</th>
                    <th>Advice</th>
                  </tr>
                </thead>
                <tbody>
                  {getExaminData?.map((item) => (
                    <>
                      <tr>
                        <td>{item.selected_teeth}</td>
                        <td>{item.disease}</td>
                        <td>{item.chief_complain}</td>
                        <td>{item.on_examination}</td>
                        <td>{item.advice}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="container-fluid">
              <div className="heading-title">
                <h6>Treatment Procedure :</h6>
              </div>
              <div className="Treatment">
                <table className="table table-bordered border">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Treatment</th>
                      <th>Teeth</th>
                      <th>Qty</th>
                      <th>Cost</th>
                      <th>Cst * Qty</th>
                      <th>Disc %</th>
                      <th>Net Treatment Amount</th>
                      <th>Sitting Amount</th>
                      <th>Paid Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sittingBill?.map((item, index) => (
                      <>
                        <tr
                          className={
                            index % 2 === 0 ? "table-primary" : "table-info"
                          }
                        >
                          <td>{item.sitting_number}</td>
                          <td>{item.treatment}</td>
                          <td>{item.teeth_number}</td>
                          <td>{item.teeth_qty}</td>
                          <td>{item.treatment_cost}</td>
                          <td>{item.treatment_cost * item.teeth_qty}</td>
                          <td>{item.discount}</td>
                          <td>{item.final_cost}</td>
                          <td>{item.sitting_amount}</td>
                          <td>{item.paid_amount ? item.paid_amount : 0}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row gutter">
                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                  <div className="border">
                    <div className="heading-title mt-0">
                      <h6>Total Amount In Words :</h6>
                    </div>
                    <div className="text-word">
                      <p className="m-0 fw-bold">
                        {" "}
                        {sittingBill[0]?.paid_amount
                          ? numToWords(
                              sittingBill[0]?.paid_amount
                            ).toUpperCase()
                          : 0}{" "}
                        RUPEES ONLY
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <div className="heading-title mt-0">
                      <h4 className="">Payment Info :</h4>
                    </div>
                    <div className="">
                      <table className="table table-bordered mb-0">
                        <tbody>
                          <tr>
                            <td className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 border p-1">
                              Account No.:
                            </td>
                            <td className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 border p-1">
                              {currentBranch[0]?.account_number}
                            </td>
                          </tr>
                          <tr>
                            <td className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 border p-1">
                              Account Name:
                            </td>
                            <td className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 border p-1">
                              {currentBranch[0]?.branch_name}
                            </td>
                          </tr>
                          <tr>
                            <td className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 border p-1">
                              Bank Name:
                            </td>
                            <td className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 border p-1">
                              {currentBranch[0]?.bank_name}
                            </td>
                          </tr>
                          <tr>
                            <td className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 border p-1">
                              IFSC/Bank Code:
                            </td>
                            <td className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 border p-1">
                              {currentBranch[0]?.ifsc_code}
                            </td>
                          </tr>
                          <tr>
                            <td className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 border p-1">
                              UPI ID:
                            </td>
                            <td className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 border p-1">
                              {currentBranch[0]?.upi_id}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                  <div className="">
                    <table className="table table-bordered mb-0">
                      <tbody>
                        <tr>
                          <td className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 border p-1 text-end total-tr">
                            Total Amount Received:
                          </td>
                          <td className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 border fw-bold p-1 text-center total-tr fs-6">
                            {sittingBill[0]?.paid_amount}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="border">
                    <div className="text-terms"></div>
                    <div className="heading-title mt-0">
                      <h6 className="text-center">Clinic Seal & Signature</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border">
                <div className="heading-title mt-0">
                  <h6>Terms and Conditions :</h6>
                </div>
                <div className="text-termslong"></div>
              </div>
            </div>
            <div className="row d-none d-print-block">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="clinic-logo">
                  <img
                    src={getBranch[0]?.foot_img}
                    alt="header"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="d-flex justify-content-center align-items-center">
            <div className="container-fluid">
              <div className="text-center">
                <button
                  className="btn btn-info no-print mx-3 mb-3 mt-2 text-white shadow"
                  style={{
                    backgroundColor: "#0dcaf0",
                    border: "#0dcaf0",
                  }}
                  onClick={handleDownloadPdf}
                >
                  Download Sitting Bill
                </button>
                <br />
                ``
                <span className="fs-5 fw-bold no-print d-print-none">
                  Share on:
                </span>
                {currentBranch[0]?.sharemail === "Yes" && (
                  <button
                    className="btn btn-info no-print mx-3 mb-3 mt-2 text-white shadow"
                    style={{
                      backgroundColor: "#0dcaf0",
                      border: "#0dcaf0",
                    }}
                    onClick={sendPrescriptionMail}
                  >
                    <SiGmail />
                  </button>
                )}
                {currentBranch[0]?.sharewhatsapp === "Yes" && (
                  <button
                    className="btn btn-info no-print mx-3 mb-3 mt-2 text-white shadow"
                    style={{
                      backgroundColor: "#0dcaf0",
                      border: "#0dcaf0",
                    }}
                    onClick={sendPrescriptionWhatsapp}
                  >
                    <IoLogoWhatsapp />
                  </button>
                )}
                {currentBranch[0]?.sharesms === "Yes" && (
                  <button
                    className="btn btn-info no-print mx-3 mb-3 mt-2 text-white shadow"
                    style={{
                      backgroundColor: "#0dcaf0",
                      border: "#0dcaf0",
                    }}
                    onClick={billDetailsSms}
                  >
                    <SiGooglemessages />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default ReceptionSittingBill;
const Wrapper = styled.div`
  font-size: 12px;
  overflow: hidden;
  background-color: white;
  height: 100%;
  .doctor-detail {
    margin-bottom: 0.5rem;
  }
  @media print {
    @page {
      margin: 2rem;
    }

    body {
      margin: 0;
    }

    .container-fluid {
      width: 100%;
      margin: 0;
      padding: 0;
      page-break-before: auto;
    }
  }
  @media print {
    .no-print {
      display: none !important;
    }
  }

  .headerimg {
    img {
      width: 100%;
      height: 8.5rem;
    }
  }

  .sign-seal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }
  .clinic-logo {
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .heading-title {
    padding: 2px;
    border-radius: 3px;
  }
  h4,
  h6 {
    margin-bottom: 0.1rem;
  }

  .docDetails {
    p {
      margin: 0rem;
    }
  }

  hr {
    margin: 0.2rem;
  }
  .text-word {
    height: auto;
  }

  .text-terms {
    height: 7.5rem;
  }

  .gutter {
    --bs-gutter-x: 0rem !important;
  }

  table > :not(caption) > * > * {
    padding: 0.2rem 0.2rem;
  }

  table {
    margin-bottom: 0.3rem;
  }

  .text-termslong {
    height: 2rem;
  }
`;
